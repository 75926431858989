import React from 'react'
import { css, SerializedStyles } from '@emotion/core'
import GridCol from './GridCol'
import {
  MOBILE,
  TABLET_OR_LESS,
  DESKTOP_OR_LESS,
  TABLET_OR_MORE,
  DESKTOP_OR_MORE,
  DESKTOP_WIDE
} from '../../../constants/theme.styles'
import { EmotionStyles } from '../../../types/types'

const rowBaseStyles = css`
  display: flex;
`

interface IWrapStyles {
  none: SerializedStyles
  all: SerializedStyles
  mobileOnly: SerializedStyles
  tabletOrLess: SerializedStyles
  desktopOrLess: SerializedStyles
}

type BreakPoints = 'none' | 'all' | 'mobileOnly' | 'tabletOrLess' | 'desktopOrLess'

const wrapStyles: IWrapStyles = {
  none: css`
    flex-wrap: nowrap;
  `,
  all: css`
    flex-wrap: wrap;
  `,
  mobileOnly: css`
    @media ${MOBILE} {
      flex-wrap: wrap;
    }
  `,
  tabletOrLess: css`
    @media ${TABLET_OR_LESS} {
      flex-wrap: wrap;
    }
  `,
  desktopOrLess: css`
    @media ${DESKTOP_OR_LESS} {
      flex-wrap: wrap;
    }
  `
}

type sizeTypes = 'all' | 'xs' | 'md' | 'lg' | 'xl'

const betweenStyles = {
  all: css`
    justify-content: space-between;
  `,
  xs: css`
    @media ${MOBILE} {
      justify-content: space-between;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      justify-content: space-between;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      justify-content: space-between;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      justify-content: space-between;
    }
  `
}

const aroundStyles = {
  all: css`
    justify-content: space-around;
  `,
  xs: css`
    @media ${MOBILE} {
      justify-content: space-around;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      justify-content: space-around;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      justify-content: space-around;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      justify-content: space-around;
    }
  `
}

const endStyles = {
  all: css`
    justify-content: flex-end;
  `,
  xs: css`
    @media ${MOBILE} {
      justify-content: flex-end;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      justify-content: flex-end;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      justify-content: flex-end;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      justify-content: flex-end;
    }
  `
}

const centerStyles = {
  all: css`
    justify-content: center;
  `,
  xs: css`
    @media ${MOBILE} {
      justify-content: center;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      justify-content: center;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      justify-content: center;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      justify-content: center;
    }
  `
}

const topStyles = {
  all: css`
    align-items: flex-start;
  `,
  xs: css`
    @media ${MOBILE} {
      align-items: flex-start;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      align-items: flex-start;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      align-items: flex-start;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      align-items: flex-start;
    }
  `
}

const middleStyles = {
  all: css`
    align-items: center;
  `,
  xs: css`
    @media ${MOBILE} {
      align-items: center;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      align-items: center;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      align-items: center;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      align-items: center;
    }
  `
}

const bottomStyles = {
  all: css`
    align-items: flex-end;
  `,
  xs: css`
    @media ${MOBILE} {
      align-items: flex-end;
    }
  `,
  md: css`
    @media ${TABLET_OR_MORE} {
      align-items: flex-end;
    }
  `,
  lg: css`
    @media ${DESKTOP_OR_MORE} {
      align-items: flex-end;
    }
  `,
  xl: css`
    @media ${DESKTOP_WIDE} {
      align-items: flex-end;
    }
  `
}

interface IProps {
  wrap?: BreakPoints
  // Gap props
  gap?: number
  xsGap?: number
  mdGap?: number
  lgGap?: number
  xlGap?: number
  // distribution
  between?: sizeTypes
  around?: sizeTypes
  end?: sizeTypes
  center?: sizeTypes
  // alignment
  top?: sizeTypes
  middle?: sizeTypes
  bottom?: sizeTypes
  // other props
  css?: EmotionStyles
  onClick?: () => void
}

class Grid extends React.Component<IProps> {
  static Col = GridCol

  render() {
    const {
      wrap = 'all',
      // horizontal distribution
      between,
      around,
      end,
      center,
      // vertical alignment
      top,
      middle,
      bottom,
      // gap
      gap,
      xsGap,
      mdGap,
      lgGap,
      xlGap,
      children,
      ...rest
    } = this.props
    return (
      <div
        css={[
          rowBaseStyles,
          wrap && wrapStyles[wrap],
          between && betweenStyles[between],
          around && aroundStyles[around],
          end && endStyles[end],
          center && centerStyles[center],
          top && topStyles[top],
          middle && middleStyles[middle],
          bottom && bottomStyles[bottom],
          typeof gap === 'number' &&
            css`
              margin-left: -${gap}px;
              margin-top: -${gap}px;
            `,
          typeof xsGap === 'number' &&
            css`
              @media ${MOBILE} {
                margin-left: -${xsGap}px;
                margin-top: -${xsGap}px;
              }
            `,
          typeof mdGap === 'number' &&
            css`
              @media ${TABLET_OR_MORE} {
                margin-left: -${mdGap}px;
                margin-top: -${mdGap}px;
              }
            `,
          typeof lgGap === 'number' &&
            css`
              @media ${DESKTOP_OR_MORE} {
                margin-left: -${lgGap}px;
                margin-top: -${lgGap}px;
              }
            `,
          typeof xlGap === 'number' &&
            css`
              @media ${DESKTOP_WIDE} {
                margin-left: -${xlGap}px;
                margin-top: -${xlGap}px;
              }
            `
        ]}
        {...rest}
      >
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement<any>, {
              gap,
              xsGap,
              mdGap,
              lgGap,
              xlGap
            })
          }
        })}
      </div>
    )
  }
}
export default Grid
