import React from 'react'
import { css } from '@emotion/core'
import {
  MOBILE,
  TABLET_OR_MORE,
  DESKTOP_OR_MORE,
  DESKTOP_WIDE
} from '../../../constants/theme.styles'

const gridColBaseStyles = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

export type colSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type noOffset = 0
type negativeOffset = -1 | -2 | -3 | -4 | -5 | -6 | -7 | -8 | -9 | -10 | -11 | -12

interface IProps {
  // size props
  xs?: colSizes
  md?: colSizes
  lg?: colSizes
  xl?: colSizes
  // gap props
  gap?: number
  xsGap?: number
  mdGap?: number
  lgGap?: number
  xlGap?: number
  // offset props
  offset?: colSizes | noOffset | negativeOffset
  xsOffset?: colSizes | noOffset | negativeOffset
  mdOffset?: colSizes | noOffset | negativeOffset
  lgOffset?: colSizes | noOffset | negativeOffset
  xlOffset?: colSizes | noOffset | negativeOffset
  // other props
  onClick?: () => void
}

const GridCol: React.FC<IProps> = ({
  // col size
  xs,
  md,
  lg,
  xl,
  // gap size
  gap = 0,
  xsGap,
  mdGap,
  lgGap,
  xlGap,
  // offset size
  offset,
  xsOffset,
  mdOffset,
  lgOffset,
  xlOffset,
  children,
  ...rest
}) => {
  return (
    <div
      css={[
        gridColBaseStyles,
        typeof gap === 'number' &&
          css`
            margin-left: ${gap}px;
            margin-top: ${gap}px;
          `,
        typeof xsGap === 'number' &&
          css`
            @media ${MOBILE} {
              margin-left: ${xsGap}px;
              margin-top: ${xsGap}px;
            }
          `,
        typeof mdGap === 'number' &&
          css`
            @media ${TABLET_OR_MORE} {
              margin-left: ${mdGap}px;
              margin-top: ${mdGap}px;
            }
          `,
        typeof lgGap === 'number' &&
          css`
            @media ${DESKTOP_OR_MORE} {
              margin-left: ${lgGap}px;
              margin-top: ${lgGap}px;
            }
          `,
        typeof xlGap === 'number' &&
          css`
            @media ${DESKTOP_WIDE} {
              margin-left: ${xlGap}px;
              margin-top: ${xlGap}px;
            }
          `,

        // Width styles
        css`
          @media (-ms-high-contrast: none) {
            flex: 0 0 auto;
            max-width: calc(100% - ${gap + 1}px);
          }
        `,
        typeof xs === 'number' &&
          css`
            @media ${MOBILE} {
              flex: 0 0 calc(100% / 12 * ${xs} - ${xsGap || gap}px);
              max-width: calc(100% / 12 * ${xs} - ${xsGap || gap}px);
              @supports (-ms-ime-align: auto) {
                flex: 0 0 calc(100% / 12 * ${xs} - ${xsGap ? xsGap + 1 : gap + 1}px);
              }
            }
            @media ${MOBILE} and (-ms-high-contrast: none) {
              width: calc(100% / 12 * ${xs} - ${xsGap ? xsGap + 1 : gap + 1}px);
              max-width: none;
            }
          `,
        typeof md === 'number' &&
          css`
            @media ${TABLET_OR_MORE} {
              flex: 0 0 calc(100% / 12 * ${md} - ${mdGap || gap}px);
              max-width: calc(100% / 12 * ${md} - ${mdGap || gap}px);
              @supports (-ms-ime-align: auto) {
                flex: 0 0 calc(100% / 12 * ${md} - ${mdGap ? mdGap + 1 : gap + 1}px);
              }
            }
            @media ${TABLET_OR_MORE} and (-ms-high-contrast: none) {
              width: calc(100% / 12 * ${md} - ${mdGap ? mdGap + 1 : gap + 1}px);
              max-width: none;
            }
          `,
        typeof lg === 'number' &&
          css`
            @media ${DESKTOP_OR_MORE} {
              flex: 0 0 calc(100% / 12 * ${lg} - ${lgGap || gap}px);
              max-width: calc(100% / 12 * ${lg} - ${lgGap || gap}px);
              @supports (-ms-ime-align: auto) {
                flex: 0 0 calc(100% / 12 * ${lg} - ${lgGap ? lgGap + 1 : gap + 1}px);
              }
            }
            @media ${DESKTOP_OR_MORE} and (-ms-high-contrast: none) {
              width: calc(100% / 12 * ${lg} - ${lgGap ? lgGap + 1 : gap + 1}px);
              max-width: none;
            }
          `,
        typeof xl === 'number' &&
          css`
            @media ${DESKTOP_WIDE} {
              flex: 0 0 calc(100% / 12 * ${xl} - ${xlGap || gap}px);
              max-width: calc(100% / 12 * ${xl} - ${xlGap || gap}px);
              @supports (-ms-ime-align: auto) {
                flex: 0 0 calc(100% / 12 * ${xl} - ${xlGap ? xlGap + 1 : gap + 1}px);
              }
            }
            @media ${DESKTOP_WIDE} and (-ms-high-contrast: none) {
              width: calc(100% / 12 * ${xl} - ${xlGap ? xlGap + 1 : gap + 1}px);
              max-width: none;
            }
          `,
        // Offset styles
        typeof offset === 'number' &&
          css`
            left: calc(100% / 12 * ${offset}) !important;
          `,
        typeof xsOffset === 'number' &&
          css`
            @media ${MOBILE} {
              left: calc(100% / 12 * ${xsOffset});
            }
          `,
        typeof mdOffset === 'number' &&
          css`
            @media ${TABLET_OR_MORE} {
              left: calc(100% / 12 * ${mdOffset});
            }
          `,
        typeof lgOffset === 'number' &&
          css`
            @media ${DESKTOP_OR_MORE} {
              left: calc(100% / 12 * ${lgOffset});
            }
          `,
        typeof xlOffset === 'number' &&
          css`
            @media ${DESKTOP_OR_MORE} {
              left: calc(100% / 12 * ${xlOffset});
            }
          `
      ]}
      {...rest}
    >
      {children}
    </div>
  )
}

export default GridCol
